@font-face {
  font-family: "LCDFont";
  src: url("./5x8lcd.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

.lcd-text {
  font-family: "LCDFont";
  position: absolute;
  color: #132500;
}