body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: none;
  overflow-y: scroll !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg {
  fill: transparent;
}

.temp {
  position: absolute;
  width: 2em;
  height: 1.5em;
  left: 0;
  right: 0;
  bottom: 0;
  top: 2rem;
  margin: auto;
  color: white;
  font-size: 2em;
  text-align: center;
  font-size: 5em;
  font-weight: 700;
}

.temp span {
  font-size: 0.5em;
  vertical-align: top;
  padding: 0 0.25em;
}

.knob {
  background: transparent;
  flex: 1 1 auto;
}

.center {
  margin: 1em 0;
  display: flex;
  flex-direction: row;
  align-content: space-around;
}

.temperature {
  position: relative;
  flex: 1 1 auto;
}

.power {
  position: absolute;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  text-align: center;
  line-height: 68px;
  font-size: 40px;
  left: 0;
  right: 0;
  bottom: -36px;
  margin: auto;
  z-index: 2;
}

.power.on {
  color: white;
  background-image: linear-gradient(to bottom right, #E1D1E9, #F8AFAE);
}

.power.off {
  color: #ccc;
  background-image: linear-gradient(to bottom right, #FFFFFF, #ccc);
}

.fa-sm {
  font-size: 0.875em !important;
}

::backdrop
{
  background-color: white;
}

#PY_EDITOR {
  height: calc(100vh - 57px) !important;
  overflow: scroll;
}