.pulse-ring {
  content: '';
  border: 5px solid #1976d2;
  border-radius: 50%;
  position: absolute;
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.3, 1.3);
    opacity: 0;
  }
}
