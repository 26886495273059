.switch {
  margin: 50px auto;
  position: relative;
}

.switch label {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
}

.switch input {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
/* Electric */

.switch.electric {
  width: 50px;
  height: 100px;
}

.switch.electric label {
  background: #cbc7bc;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow:
      inset 0 1px 0 white,
      0 0 0 1px #999,
      0 0 5px 1px rgba(0,0,0,0.2),
      0 2px 0 rgba(255,255,255,0.6),
      inset 0 10px 1px #e5e5e5,
      inset 0 11px 0 rgba(255,255,255,0.5),
      inset 0 -45px 3px #ddd;
}

.switch.electric label:after {
  content: "";
  position: absolute;
  top: -20px;
  left: -25px;
  bottom: -20px;
  right: -25px;
  background: #ccc;
  background: -moz-linear-gradient(#ddd, #bbb);
  background: -ms-linear-gradient(#ddd, #bbb);
  background: -o-linear-gradient(#ddd, #bbb);
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#ddd), to(#bbb));
  background: -webkit-linear-gradient(#ddd, #bbb);
  background: linear-gradient(#ddd, #bbb);
  z-index: -1;
  border-radius: 5px;
  border: 1px solid #bbb;
  box-shadow:
      0 0 5px 1px rgba(0,0,0,0.15),
      0 3px 3px rgba(0,0,0,0.3),
      inset 0 1px 0 rgba(255,255,255,0.5);
}

.switch.electric label:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: #666;
  top: -13px;
  left: 20px;
  border-radius: 50%;
  box-shadow:
      0 120px 0 #666,
      0 1px 0 white,
      0 121px 0 white;
}

.switch.electric input:checked ~ label {
  background: #d2cbc3;
  box-shadow:
      inset 0 1px 0 white,
      0 0 0 1px #999,
      0 0 5px 1px rgba(0,0,0,0.2),
      inset 0 -10px 0 #aaa,
      0 2px 0 rgba(255,255,255,0.1),
      inset 0 45px 3px #e0e0E0,
      0 8px 6px rgba(0,0,0,0.18);
}
