.knobComp {
  position: relative;
  width: 260px;
  height: 260px;
  margin: 20px auto;
  background: url('./bg.png');
  background-repeat: no-repeat;
}

.knobComp>div:first-child {
  background: url('./knob.png');
  background-repeat: no-repeat;
}
