*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.switchT {
  margin: 10px auto;
  position: relative;
}

.switchT label {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
}

.switchT input {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.switchT.toggleonoff {
  width: 180px;
  height: 50px;
}

.switchT.toggleonoff label {
  display: block;
  width: 100%;
  height: 100%;
  background: #a5a39d;
  border-radius: 40px;
  box-shadow:
      inset 0 3px 8px 1px rgba(0,0,0,0.2),
      0 1px 0 rgba(255,255,255,0.5);
}

.switchT.toggleonoff label:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: -8px; right: -8px; bottom: -8px; left: -8px;
  border-radius: inherit;
  background: #ababab;
  background: -moz-linear-gradient(#f2f2f2, #ababab);
  background: -ms-linear-gradient(#f2f2f2, #ababab);
  background: -o-linear-gradient(#f2f2f2, #ababab);
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#f2f2f2), to(#ababab));
  background: -webkit-linear-gradient(#f2f2f2, #ababab);
  background: linear-gradient(#f2f2f2, #ababab);
  box-shadow: 0 0 10px rgba(0,0,0,0.3),
        0 1px 1px rgba(0,0,0,0.25);
}

.switchT.toggleonoff label:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -18px; right: -18px; bottom: -18px; left: -18px;
  border-radius: inherit;
  background: #eee;
  background: -moz-linear-gradient(#e5e7e6, #eee);
  background: -ms-linear-gradient(#e5e7e6, #eee);
  background: -o-linear-gradient(#e5e7e6, #eee);
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#e5e7e6), to(#eee));
  background: -webkit-linear-gradient(#e5e7e6, #eee);
  background: linear-gradient(#e5e7e6, #eee);
  box-shadow:
      0 1px 0 rgba(255,255,255,0.5);
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -ms-filter: blur(1px);
  -o-filter: blur(1px);
  filter: blur(1px);
}

.switchT.toggleonoff label i {
  display: block;
  height: 100%;
  width: 60%;
  border-radius: inherit;
  background: silver;
  position: absolute;
  z-index: 2;
  right: 40%;
  top: 0;
  background: #b2ac9e;
  background: -moz-linear-gradient(#f7f2f6, #b2ac9e);
  background: -ms-linear-gradient(#f7f2f6, #b2ac9e);
  background: -o-linear-gradient(#f7f2f6, #b2ac9e);
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#f7f2f6), to(#b2ac9e));
  background: -webkit-linear-gradient(#f7f2f6, #b2ac9e);
  background: linear-gradient(#f7f2f6, #b2ac9e);
  box-shadow:
      inset 0 1px 0 white,
      0 0 8px rgba(0,0,0,0.3),
      0 5px 5px rgba(0,0,0,0.2);
}

.switchT.toggleonoff label i:after {
  content: "";
  position: absolute;
  left: 15%;
  top: 25%;
  width: 70%;
  height: 50%;
  background: #d2cbc3;
  background: -moz-linear-gradient(#cbc7bc, #d2cbc3);
  background: -ms-linear-gradient(#cbc7bc, #d2cbc3);
  background: -o-linear-gradient(#cbc7bc, #d2cbc3);
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#cbc7bc), to(#d2cbc3));
  background: -webkit-linear-gradient(#cbc7bc, #d2cbc3);
  background: linear-gradient(#cbc7bc, #d2cbc3);
  border-radius: inherit;
}

.switchT.toggleonoff label i:before {
  content: "off";
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  color: rgba(0,0,0,0.4);
  text-shadow: 0 1px 0 #bcb8ae, 0 -1px 0 #97958e;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 24px;
  position: absolute;
  top: 50%;
  margin-top: -12px;
  right: -50%;
}

.switchT.toggleonoff input:checked ~ label {
  background: #9abb82;
}

.switchT.toggleonoff input:checked ~ label i {
  right: -1%;
}

.switchT.toggleonoff input:checked ~ label i:before {
  content: "on";
  right: 115%;
  color: #82a06a;
  text-shadow:
    0 1px 0 #afcb9b,
    0 -1px 0 #6b8659;
}

.buttonRound {
  position: relative;
  font-size: 16px;
  padding: 0 20px;
  display: inline-block;
  border: none;
  outline: none;
  background: #FFF;
  border-radius: 3px;
  border: 1px solid #8D8D8D;
  box-shadow: 0 1px 3px 0px rgba(144, 144, 144, 0.5), inset 0 1px rgba(255, 255, 255, 0.2);
}

.buttonRound {
  height: 4em;
  width: 4em;
  border-radius: 100%;
}

.buttonDark {
  background: linear-gradient(#D3D3D3, #B4B4B4);
  background-size: auto 150%;
  background-position: 0 100%;
}
.buttonDark:hover {
  background-position: 0 50%;
}
.buttonDark:active {
  color: #D3D3D3;
  background-position: 0 50%;
  background: #767676;
  box-shadow: inset 0 2px 3px rgba(118, 118, 118, 0.5), 0 1px 1px rgba(255, 255, 255, 0.1);
}
.buttonDark:active .icon:before {
  color: #8D8D8D;
  text-shadow: 0 1px rgba(255, 255, 255, 0.1);
}

.icon:before {
  font-style: normal;
  font-size: 2.6rem;
  display: block;
  color: #DDD;
  text-shadow: 0 1px 1px #333;
  content: '⚫';
  margin-left: auto;
  margin-right: auto;
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 0.35rem;
  left: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.buttonDark:active .icon:before {
  content: '🟢';
}